import { Fragment, useState, useEffect } from 'react'
import { motion } from "framer-motion"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyAj16lIVj_Igb2AOO_MUhLJWsLqCDkuB3U",
  authDomain: "chovain-6fb05.firebaseapp.com",
  databaseURL: "https://chovain-6fb05.firebaseio.com",
  projectId: "chovain-6fb05",
  storageBucket: "chovain-6fb05.appspot.com",
  messagingSenderId: "350850689440",
  appId: "1:350850689440:web:b1646aae96509ac9b89cc5",
  measurementId: "G-9VP01NDSXJ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
// const db = getFirestore(app);

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export default function App() {
  const [userAgent, setUserAgent] = useState('');

  useEffect(() => {
    setUserAgent(getMobileOperatingSystem());
  }, []);

  useEffect(() => {
    if (userAgent) {
      logEvent(analytics, 'visit_register_page', {
        user_agent: userAgent,
      });

      window.location.href = 'chovainpro://';
    }
  }, [userAgent]);

  return (
    <>
      <div className="w-[100vh] h-full fixed rotate-[60deg] right-[100%] top-[-50%] rounded-[50px] bg-[#e1dddb]"/>
      <div className="w-[100vh] h-full fixed rotate-[60deg] left-[75%] top-[5%] rounded-[50px] bg-[#d8bdb1]"/>
      <div className="flex flex-col h-full relative z-20">
        <div className="flex justify-center items-center pt-4">
            <img src="/CHOVAIN_Logo_Black.png" className="w-[180px] h-auto" alt="CHOVAIN" />
        </div>
        <div className="flex flex-col grow justify-center items-start p-4 md:p-16 max-w-[960px] w-full self-center">
          <h1 className="text-3xl text-neutral-900 font-medium md:text-center w-full md:mb-2 text-center">Opening the app...</h1>
        </div>
      </div>
    </>
  )
}
